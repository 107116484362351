<template>
  <div :class="{open: isOpen}" class="history-list__item history-item" @click="toggleHistoryItem">
    <div class="history-item__top">
      <div class="history-item__title" v-if="item.TypeOperation === 'Приход'">Начисление балов</div>
      <div class="history-item__title" v-else>Списание балов</div>
      <div class="history-item__date">{{ formatDate(item.Period) }}</div>
    </div>
    <div class="history-item__md">
      <div class="history-item__sum">
        <span v-if="item.TypeOperation === 'Приход'">+</span>
        <span v-else>-</span>
        {{ item.BonusCount }}
      </div>
      <span class="history-dots">...</span>
      <span class="history-close"></span>
    </div>
    <div class="history-item-bot">
      <div class="history-order-row">
        <img src="../assets/images/icons/status-send.svg" class="history-status-ico" alt="img">
        <div class="history-help">
          <span class="history-help__ico"></span>
          <ul class="history-tooltip">
            <li class="history-tooltip__item"><span class="tooltip-ico send"></span>заказ отправлен</li>
            <li class="history-tooltip__item"><span class="tooltip-ico get"></span>заказ доставлен</li>
            <li class="history-tooltip__item"><span class="tooltip-ico processing"></span>заказ в обработке</li>
            <li class="history-tooltip__item"><span class="tooltip-ico declined"></span>заказ отменен</li>
          </ul>
        </div>
      </div>
      <div class="history-products">
        <div class="history-product">
          <div class="history-product__name">{{ item.Description }}</div>
          <div class="product-history-price">{{ item.BonusCount }}
            <span class="product-history-price__ico"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: {
        required: true,
      }
    },

    data() {
      return {
        isOpen: false
      }
    },

    methods: {
      toggleHistoryItem() {
        this.isOpen = !this.isOpen
      },

      formatDate(date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [day, month, year].join('.');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .history-list {
    margin-top: 40px;

    &__item {
      padding-bottom: 20px;
      border-bottom: 1px solid #5A5A5A;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .history-item {
    cursor: pointer;
    &__top {
      display: flex;
      align-items: center;
      padding-top: 20px;
    }

    &__title {
      margin-right: auto;
      color: #8B8B8B;
      font: 300 16px/19px $roboto;

    }

    &__date {
      margin-left: auto;
      color: #8B8B8B;
      font: 300 16px/19px $roboto;
    }

    &__md {
      display: flex;
      align-items: center;
      position: relative;
      padding-top: 20px;
    }

    &__sum {
      color: #FF9C43;
      font: 300 16px/19px $roboto;
      margin-right: auto;
      margin-left: 15px;

      span {
        color: #FFFEFA;
        padding-right: 10px;
      }
    }
  }

  .history-dots {
    font: 300 60px/0 $roboto;
    color: #FF9C43;
    position: absolute;
    right: 0;
    top: 12px;
    letter-spacing: -3px;
    cursor: pointer;

  }

  .history-close {
    display: block;
    width: 10px;
    height: 10px;
    right: 0;
    top: 50%;
    margin-top: -5px;
    background-image: url('../assets/images/icons/close-menu.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    display: none;
  }

  .history-item-bot {
    padding-top: 20px;
    display: none;
  }

  .history-order-row {
    display: flex;
    align-items: center;
  }

  .history-order {
    height: 27px;
    background: #232525;
    padding: 0 10px;
    border-radius: 4px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    font: 400 12px/27px $roboto;
    color: #C4C4C4;
  }

  .history-status-ico {
    margin-left: 20px;
  }

  .history-help {
    display: block;
    margin-left: auto;
    width: 30px;
    height: 30px;
    position: relative;

    &__ico {
      display: block;
      width: 30px;
      height: 30px;
      background-image: url('../assets/images/icons/help.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  .history-tooltip {
    position: absolute;
    display: block;
    width: 255px;
    height: 146px;
    background: #1A1C1C;
    border: 1px solid #3E3E3E;
    border-radius: 4px;
    padding-top: 14px;
    top: 3px;
    right: 33px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s;

    &__item {
      display: flex;
      align-items: center;
      font: 400 18px/21px $roboto;
      color: #C4C4C4;
      padding-left: 38px;
      margin-bottom: 10px;
    }
  }

  .tooltip-ico {
    display: block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  .send {
    background-image: url('../assets/images/icons/status-send.svg');
  }

  .get {
    background-image: url('../assets/images/icons/status-get.svg');
  }

  .processing {
    background-image: url('../assets/images/icons/status-processing.svg');
  }

  .declined {
    background-image: url('../assets/images/icons/status-declined.svg');
  }

  .history-help {
    &:hover {
      .history-tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .history-products {
    background: #232525;
    border-radius: 4px;
    padding: 5px 10px 0 10px;
  }

  .history-product {
    height: 55px;
    border-bottom: 1px solid #5A5A5A;
    display: flex;
    align-items: center;
    &:last-child {
      border: none;
    }

    &__name {
      color: #C4C4C4;
      font: 400 14px/16px $roboto;
    }
  }

  .product-history-price {
    font: 400 16px/19px $roboto;
    color: #FFFEFA;
    display: flex;
    margin: 5px 0 0 auto;

    &__ico {
      width: 15px;
      height: 14px;
      display: block;
      background-image: url('../assets/images/icons/coins.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      align-self: flex-end;
      margin: 0 0 2px 5px;
    }
  }

  .open .history-item-bot, .open .history-close {
    display: block;
  }

  .open .history-dots {
    display: none;
  }
</style>