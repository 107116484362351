<template>
	<div>
        <Header/>
        <div class="content">
            <side-nav />
            <slot/>
        </div>
    
        <bottom-menu v-if="!this.isModalOpen('notificationModal')"/>
    </div>
</template>

<script>
    import Header from '@/components/Header'
    import SideNav from '@/components/SideNav'
    import BottomMenu from '@/components/BottomMenu/Index.vue'
   
    import { mapGetters } from 'vuex';

    export default {
        name: "default-layout",
        components: {
            Header,
            BottomMenu,
            SideNav,
        },

   computed: {
       ...mapGetters([
      'isModalOpen'
    ])
  },
    }
</script>

<style lang="scss" scoped>
    .content {
        position: relative;
		margin-top: 60px;
    }
</style>